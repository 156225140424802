<template>
  <div class="voice-manage">
    <el-row>
      <div class="btn-container">
        <div
          class="btn-item"
          :class="{ selected: selectedBtn === 1 }"
          @click="handleTopBtnClick(1)"
        >
          现场报到
        </div>
        <!-- <div
          class="btn-item"
          :class="{ selected: selectedBtn === 2 }"
          @click="handleTopBtnClick(2)"
        >
          现场抽签
        </div> -->
        <div
          class="btn-item"
          :class="{ selected: selectedBtn === 3 }"
          @click="handleTopBtnClick(3)"
        >
          监考端
        </div>
      </div>
    </el-row>
    <!-- 现场报到和现场抽签页面 -->
    <template v-if="selectedBtn === 1 || selectedBtn === 2">
      <el-row style="margin-top: 20px">
        <div class="voice-manage-content">
          <div class="left-side">
            <div>文本转语音</div>
            <div class="word-voice-container">
              <el-tag
                :key="tag.id"
                v-for="(tag, i) in addedVoiceContent"
                closable
                :disable-transitions="false"
                @close="handleDelAddedVoiceContentBtn(i)"
              >
                {{ tag.name }}
              </el-tag>
              <div class="voice-content">
                <div
                  class="voice-content-content"
                  @click="handleAddVoiceContent"
                >
                  插入播报字段
                </div>

                <div class="voice-popover" ref="voicePopover">
                  <div
                    v-for="item in voiceContent"
                    :key="item.id"
                    class="voice-popover-item"
                    ref="voicePopoverItem"
                    @click="handleAddVoiceContentBtnClick(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="right-side">
            <div>语音设置</div>
            <div style="margin-top: 20px; font-size: 14px">语音类型</div>
            <div style="margin-top: 20px">
              <div
                v-for="(item, i) in voiceType"
                :key="item.id"
                class="voice-type-item"
                :class="{ selected: selectedType === i + 1 }"
                @click="
                  () => {
                    selectedType = i + 1;
                  }
                "
              >
                {{ item.name }}
              </div>
            </div>
            <div style="margin-top: 20px; font-size: 14px">更多设置</div>
            <div style="display: flex; margin-top: 10px; margin-left: 10px">
              <div style="margin-right: 20px; height: 38px; line-height: 38px">
                音量:
              </div>
              <div style="width: 430px; height: 38px">
                <el-slider v-model="volume" show-input> </el-slider>
              </div>
            </div>
            <div style="display: flex; margin-top: 10px; margin-left: 10px">
              <div style="margin-right: 20px; height: 38px; line-height: 38px">
                语调:
              </div>
              <div style="width: 430px; height: 38px">
                <el-slider v-model="intonation" show-input> </el-slider>
              </div>
            </div>
            <div style="display: flex; margin-top: 10px; margin-left: 10px">
              <div style="margin-right: 20px; height: 38px; line-height: 38px">
                语速:
              </div>
              <div style="width: 430px; height: 38px">
                <el-slider v-model="speed" show-input> </el-slider>
              </div>
            </div>
            <div style="display: flex; margin-top: 10px; margin-left: 10px">
              <div style="margin-right: 20px; height: 38px; line-height: 38px">
                延迟:
              </div>
              <div style="width: 430px; height: 38px">
                <span style="color: #ccc">文本播放延迟</span>
                <el-input-number
                  v-model="delay"
                  controls-position="right"
                  :min="1"
                  :max="5"
                  style="width: 100px; margin-left: 20px"
                ></el-input-number>
              </div>
            </div>
          </div> -->
        </div>
      </el-row>
      <div class="bottom-btn">
        <el-button>试听一下</el-button>
        <el-button type="primary">保存设置</el-button>
      </div>
    </template>
    <!-- 监考端区域 -->
    <template v-else>
      <div
        style="display: flex; justify-content: space-between; margin: 20px 50px"
      >
        <div style="line-height: 40px">语音播报</div>
        <el-button
          @click="handleAddVoiceBroadcastBtnClick"
          icon="el-icon-circle-plus-outline"
          type="primary"
          v-throttle
          >新增语音</el-button
        >
      </div>
      <el-row>
        <el-table
          border
          :data="voiceTableData"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          height="600"
        >
          <el-table-column label="语音名称" prop="name"></el-table-column>
          <el-table-column
            label="语音编码"
            prop="code"
            width="250px"
          ></el-table-column>
          <el-table-column label="语音" width="250px">
            <template slot-scope="scoped">
              <div
                style="
                  height: 30px;
                  line-height: 30px;
                  display: flex;
                  margin-left: 90px;
                "
              >
                <audio
                  :ref="'audio' + scoped.row.code"
                  :src="voicePathFunc(scoped.row.path)"
                ></audio>
                <img
                  v-if="scoped.row.isPlaying"
                  src="./img/pauseCircle.svg"
                  style="
                    width: 28px;
                    height: 28px;
                    margin-right: 6px;
                    margin-top: 1px;
                    margin-left: 1px;
                    cursor: pointer;
                  "
                  @click="handleVoicePlayBtnClick(scoped.row)"
                />
                <img
                  v-else
                  src="./img/playCircle.svg"
                  style="
                    width: 30px;
                    height: 30px;
                    margin-right: 5px;
                    cursor: pointer;
                  "
                  @click="handleVoicePlayBtnClick(scoped.row)"
                />
                <div>{{ scoped.row.duration }}"</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300px">
            <template slot-scope="scoped">
              <div style="display: flex; margin-left: 110px">
                <div
                  style="
                    margin-right: 10px;
                    cursor: pointer;
                    color: green;
                    text-decoration: underline;
                  "
                  @click="handleEditVoiceBtnClick(scoped.row)"
                >
                  编辑
                </div>
                <div
                  @click="handleDeleteVoiceBtnClick(scoped.row)"
                  style="
                    cursor: pointer;
                    color: red;
                    text-decoration: underline;
                  "
                >
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div style="margin-top: 40px; margin-left: 700px">
        <el-button
          type="primary"
          style="width: 150px"
          @click="handleSaveSetBtnClick"
          >保存设置</el-button
        >
      </div>
    </template>
    <el-dialog
      :title="addOrEditVoice === 1 ? '新增语音' : '编辑语音'"
      :visible.sync="voiceDialogVisible"
      width="600px"
    >
      <div style="width: 500px; margin: 0 auto">
        <el-form
          :model="voiceBroadcastForm"
          ref="voiceBroadcastForm"
          class="voiceBroadcastForm"
          :rules="rules"
        >
          <el-form-item prop="name" label="语音名称">
            <el-input
              placeholder="请输入"
              v-model.trim="voiceBroadcastForm.name"
              maxlength="20"
              style="width: 400px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code" label="语音编号">
            <el-input
              placeholder="请输入"
              v-model.trim="voiceBroadcastForm.code"
              maxlength="20"
              style="width: 400px"
              :disabled="addOrEditVoice === 0"
            ></el-input>
          </el-form-item>
          <el-form-item prop="fileName" label="语音文件">
            <div style="display: flex">
              <el-input
                placeholder="请上传文件"
                disabled
                v-model="voiceBroadcastForm.fileName"
                style="width: 282px; margin-right: 10px"
              ></el-input>
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :http-request="voiceUploadFunc"
                :show-file-list="false"
                accept=".m4a, .mp3"
                :multiple="false"
              >
                <el-button type="primary" icon="el-icon-upload2"
                  >点击上传</el-button
                >
              </el-upload>
            </div>
            <div style="margin-left: 82px; color: #ccc">
              请上传m4a、mp3格式文件
            </div>
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button
            v-throttle
            @click="
              () => {
                this.voiceDialogVisible = false;
              }
            "
            >取消</el-button
          >
          <el-button
            type="primary"
            v-throttle
            @click="handleConfimVoiceBtnClick"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getVoiceBroadcastListApi,
  addVoiceBroadcastApi,
  deleteVoiceBroadcastApi,
  getVoiceBroadcastDetailApi,
  updateVoiceBroadcastApi,
  voiceBroadcastUploadApi,
} from "@/api/system/voiceManage.js";
export default {
  name: "voiceManage",
  data() {
    const checkCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z]/g;
      if (reg.test(this.voiceBroadcastForm.code)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      selectedBtn: 3,
      isVoicePopoverShow: false,
      voiceContent: [
        { name: "考生姓名", id: 1 },
        { name: "考生考号", id: 2 },
        { name: "考生学校", id: 3 },
        { name: "考生班级", id: 4 },
        { name: "座位号", id: 5 },
      ],
      addedVoiceContent: [],
      voiceType: [
        { name: "原声", id: 1 },
        { name: "机械声", id: 2 },
        { name: "男声", id: 3 },
        { name: "女声", id: 4 },
        { name: "儿童声", id: 5 },
        { name: "老人声", id: 6 },
      ],
      selectedType: 1,
      volume: 50, // 音量
      intonation: 50, // 语调
      speed: 50,
      delay: 5,
      voiceTableData: [],
      platformType: 31,
      addOrEditVoice: 1, // 1 新增 0 编辑
      voiceDialogVisible: false,
      voiceBroadcastForm: {
        name: "",
        code: "",
        fileName: "",
      },
      rules: {
        name: [{ required: true, message: "请输入语音名称", trigger: "blur" }],
        code: [
          { required: true, message: "请输入语音编号", trigger: "blur" },
          { validator: checkCode, trigger: "blur" },
        ],
        fileName: [
          { required: true, message: "请上传语音文件", trigger: "blur" },
        ],
      },
      voicePath: "",
      audioDuration: "", // 上传音频时长，单位秒
      serverVoiceBroadcastId: "",
    };
  },
  mounted() {
    window.addEventListener(
      "click",
      (e) => {
        if (
          this.isVoicePopoverShow &&
          e.target.className !== "voice-popover-item" &&
          e.target.className !== "voice-content-content"
        ) {
          this.handleAddVoiceContent();
        }
      },
      true
    );
    this.fetchVoiceBroadcastListInfo();
  },
  beforeDestroy(){
    this.handlePauseAllFunc()
  },
  methods: {
    // 获取语音播报列表
    fetchVoiceBroadcastListInfo() {
      getVoiceBroadcastListApi(this.platformType).then((res) => {
        if (res.success) {
          this.voiceTableData = res.data.map((item) => ({
            ...item,
            isPlaying: false,
            originDuration: item.duration,
          }));
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 顶部按钮点击事件
    handleTopBtnClick(num) {
      this.selectedBtn = num;
      if (num === 1) this.platformType = 23;
      if (num === 2) this.platformType = 25;
      if (num === 3) this.platformType = 31;
      this.fetchVoiceBroadcastListInfo();
    },
    // 插入播报字段点击事件
    handleAddVoiceContent() {
      if (!this.isVoicePopoverShow) {
        this.$refs.voicePopover.style.transform = "scaleY(1)";
      } else {
        this.$refs.voicePopover.style.transform = "scaleY(0)";
      }
      this.isVoicePopoverShow = !this.isVoicePopoverShow;
    },
    handleAddVoiceContentBtnClick(item) {
      let result = true;
      for (let i = 0; i < this.addedVoiceContent.length; i++) {
        if (this.addedVoiceContent[i].id === item.id) {
          result = false;
        }
      }
      if (result) {
        this.addedVoiceContent.push(item);
      }
    },
    // 删除播报字段
    handleDelAddedVoiceContentBtn(index) {
      this.addedVoiceContent.splice(index, 1);
    },
    // 新增语音按钮
    handleAddVoiceBroadcastBtnClick() {
      this.addOrEditVoice = 1;
      this.voiceBroadcastForm = {
        name: "",
        code: "",
        fileName: "",
      };
      this.voicePath = "";
      this.voiceDialogVisible = true;
    },
    // 编辑语音按钮
    handleEditVoiceBtnClick(row) {
      this.handlePauseAllFunc();
      getVoiceBroadcastDetailApi(row.serverVoiceBroadcastId).then((res) => {
        // console.log(res);
        if (res.success) {
          const { data } = res;
          this.voiceBroadcastForm = {
            name: data.name,
            code: data.code,
            fileName: `${data.name}.${data.path.split(".")[1]}`,
          };
          this.addOrEditVoice = 0;
          this.voicePath = data.path;
          this.audioDuration = data.duration;
          this.serverVoiceBroadcastId = row.serverVoiceBroadcastId;
          this.voiceDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 确认语音按钮
    handleConfimVoiceBtnClick() {
      this.$refs.voiceBroadcastForm.validate((valid) => {
        if (valid) {
          if (this.addOrEditVoice === 1) {
            let result = false;
            this.voiceTableData.forEach((item) => {
              if (item.code == this.voiceBroadcastForm.code) {
                result = true;
              }
            });
            if (result) {
              this.$message.error("编码重复!");
              return;
            }
            // 新增
            let addParam = {
              code: this.voiceBroadcastForm.code,
              duration: this.audioDuration,
              enable: 1,
              name: this.voiceBroadcastForm.name,
              platformType: 31,
              path: this.voicePath,
              url: this.voicePath,
            };
            addVoiceBroadcastApi(addParam).then((res) => {
              if (res.success) {
                this.$message.success("新增成功!");
                this.fetchVoiceBroadcastListInfo();
                this.voiceDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 编辑
            let editParams = {
              code: this.voiceBroadcastForm.code,
              duration: this.audioDuration,
              name: this.voiceBroadcastForm.name,
              platformType: 31,
              path: this.voicePath,
              url: this.voicePath,
              serverVoiceBroadcastId: this.serverVoiceBroadcastId,
            };
            updateVoiceBroadcastApi(editParams).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功!");
                this.fetchVoiceBroadcastListInfo();
                this.voiceDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 音频上传
    voiceUploadFunc(item) {
      // console.log(item);
      const whiltList = ["m4a", "mp3"];
      const fileType = item.file.name.replace(/.+\./, "");
      if (whiltList.indexOf(fileType.toLowerCase()) === -1) {
        this.$message.error("文件格式不正确，请重新上传！");
        return;
      }
      var url = URL.createObjectURL(item.file);
      var audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", (_event) => {
        this.audioDuration = parseInt(audioElement.duration);
        // console.log(this.audioDuration);
      });
      // console.log(audioElement);
      let fd = new FormData();
      fd.append("file", item.file);
      fd.append("code", "default");
      voiceBroadcastUploadApi(fd).then((res) => {
        if (res.success) {
          // console.log(res, "上传");
          this.voiceBroadcastForm.fileName = item.file.name;
          this.voicePath = res.data.url;
          this.$message.success("上传成功!");
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 播放或者暂停语音
    handleVoicePlayBtnClick(row) {
      if (!row.timer) {
        row.timer = null;
      }
      if (row.isPlaying) {
        // 正在播放，暂停
        // console.log("暂停")
        this.$refs["audio" + row.code].pause();
        clearInterval(row.timer);

        row.isPlaying = !row.isPlaying;
      } else {
        // 正在暂停，播放
        // console.log("播放")
        this.handlePauseAllFunc();
        this.$refs["audio" + row.code].play();
        row.timer = setInterval(() => {
          if (row.duration <= 0) {
            row.duration = row.originDuration;
            row.isPlaying = !row.isPlaying;
            clearInterval(row.timer);
          } else {
            row.duration--;
          }
        }, 1000);

        row.isPlaying = !row.isPlaying;
      }
    },
    // 调用该方法暂停全部播放
    handlePauseAllFunc() {
      if (this.voiceTableData.length > 0) {
        this.voiceTableData.forEach((item) => {
          this.$refs["audio" + item.code].currentTime = 0;
          this.$refs["audio" + item.code].pause();
          item.duration = item.originDuration;
          clearInterval(item.timer);
          item.isPlaying = false;
        });
      }
    },
    // 删除语音按钮
    handleDeleteVoiceBtnClick(row) {
      this.$confirm("确定要删除该语音吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteVoiceBroadcastApi([row.serverVoiceBroadcastId]).then((res) => {
            if (res.success) {
              this.$message.success("删除成功!");
              this.fetchVoiceBroadcastListInfo();
            } else {
              this.$message.error(res.msg);
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSaveSetBtnClick() {
      this.$message.success("设置成功!");
    },
    voicePathFunc(path) {
      return this.$imageAddress(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  border-radius: 5px;
  width: 220px;
  border: 1px solid #ccc;
}
.btn-item {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(24, 144, 255);
    color: #fff;
  }
  &.selected {
    background-color: rgb(24, 144, 255);
    color: #fff;
  }
}
.btn-item:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.btn-item:nth-child(2) {
  border-radius: 0 5px 5px 0;
}
.voice-manage-content {
  display: flex;
}
.left-side {
  width: 100%;
  height: 500px;
  background-color: rgb(245, 246, 250);
  border-radius: 10px;
  margin-right: 28px;
  padding: 20px;
}
.right-side {
  width: 500px;
  height: 500px;
  padding: 20px;
  background-color: rgb(245, 246, 250);
  border-radius: 10px;
}
::v-deep .word-voice-container {
  width: 97%;
  height: 410px;
  margin-top: 20px;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  display: flex;
  padding: 20px;
  .el-tag--light {
    margin-right: 10px;
  }
  .word-voice-item {
    display: flex;
    justify-content: space-between;
    color: rgb(24, 144, 255);
    background-color: rgb(232, 244, 255);
    width: 100px;
    height: 20px;
    line-height: 20px;
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
    .item-icon {
      cursor: pointer;
    }
  }
  .voice-content {
    color: rgb(24, 144, 255);
    background-color: rgb(232, 244, 255);
    width: 120px;
    height: 40px;
    cursor: pointer;
    border-radius: 0 10px 0 10px;
    line-height: 40px;
    text-align: center;
    left: 0;
    bottom: 0;
    position: absolute;
    font-size: 14px;
  }
  .voice-popover {
    left: 5px;
    bottom: 45px;
    position: absolute;
    width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transform: scaleY(0);
    transition: 0.1s;
    transform-origin: bottom;
    .voice-popover-item {
      width: 100px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: #000;
      cursor: pointer;
      &:hover {
        color: rgb(24, 144, 255);
        background-color: rgb(232, 244, 255);
      }
    }
  }
}
.voice-type-item {
  display: inline-block;
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &.selected {
    border-color: rgb(24, 144, 255);
    color: rgb(24, 144, 255);
  }
}
::v-deep .voiceBroadcastForm {
  .el-form-item__error {
    margin-left: 80px;
  }
}
.bottom-btn {
  width: 300px;
  margin: 40px auto;
}
</style>
