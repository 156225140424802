import { request, noTimeOutReq } from "@/utils/request.js";

// 根据类型获取语音播报列表，PLATFORM_FRONT_CALL_BATCH(23, "平台报到"), PLATFORM_FRONT_DRAW(25, "平台抽签"), TEACHER_TERMINAL(31, "教师终端"),
export function getVoiceBroadcastListApi(platformType) {
  return request({
    url: `/server/voice/broadcast/type/${platformType}/list`,
    method: "post",
  });
}

// 新增语音播报
export function addVoiceBroadcastApi(data) {
  return request({
    url: `/server/voice/broadcast/type/add`,
    method: "post",
    data,
  });
}

// 删除语音播报
export function deleteVoiceBroadcastApi(data) {
  return request({
    url: `/server/voice/broadcast/type/delete`,
    method: "post",
    data,
  });
}

// 获取语音播报详情
export function getVoiceBroadcastDetailApi(serverVoiceBroadcastId) {
  return request({
    url: `/server/voice/broadcast/type/get/${serverVoiceBroadcastId}`,
    method: "post",
  });
}

// 修改语音播报
export function updateVoiceBroadcastApi(data) {
  return request({
    url: `/server/voice/broadcast/type/update`,
    method: "post",
    data,
  });
}

// 语音上传接口
export function voiceBroadcastUploadApi(data) {
  return request({
    url: "/function/oss/upload/single/file",
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    data,
  });
}
